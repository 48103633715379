import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Pagination from 'react-bootstrap/Pagination';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom'


const Tab4 = ({cli}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
 
  const [data, setData] = useState([]);
  const [modalAbierta, setModalAbierta] = useState(false);
  const [datosModal, setDatosModal] = useState([]);
  const [prodidModal, setProdidModal] = useState(null);
  const [carroItems, setCarroItems] = useState([]);
  const [cantidadCarro, setCantidadCarro] = useState('');
  const [lineas, setLineas] = useState([]);
  const [lineaSeleccionada, setLineaSeleccionada] = useState('');
  const [marcas, setMarcas] = useState([]);
  const [marcaSeleccionada, setMarcaSeleccionada] = useState('');
  

  const location = useLocation();
  const productos = new URLSearchParams(location.search).get("productos");

  const [productosHabilitados, setProductosHabilitados] = useState([new Set]);

  useEffect(() => {
    // Divide la cadena de productos en un array y establece el estado
    setProductosHabilitados(new Set(productos ? productos.split(',') : []));
  }, [productos]);

  //const [data2, setData2] = useState([]);

  const loadData = async () => {
        

        // let buscar = document.getElementById('types_input').value;
        await axios.get("https://apigabtecbi.automarco.cl:3003/api/nuevos", {
            params: {
              ven:  cli ,
            }
        }).then((response) => {
            
            setData(response.data);
         })
     
        
  };

  const loadModalData = async (prodid) => {
    try {
      const response = await axios.get("https://apigabtecbi.automarco.cl:3003/api/aplicaciones", {
        params: {
            prodid: prodid
        }
      });
      const modalData = response.data;
      
      // Aquí puedes utilizar modalData para llenar tu modal con la información necesaria
    
      setDatosModal(modalData);
      setProdidModal(prodid);
      //console.log(modalData);
    } catch (error) {
      console.error('Error al cargar datos de la modal:', error);
    }
  };
  
  const effectRan = useRef(false);

  useEffect(() => {
    const input = document.getElementById('search');
    if (input) {
        input.setAttribute('autocomplete', 'off');
    }
    if (!effectRan.current) {
    loadData();
    }
    return () => {effectRan.current = true};
  },[]);

useEffect(() => {
    const fetchLineas = async () => {
        try {
            const response = await axios.get("https://apigabtecbi.automarco.cl:3003/api/select-lineas-nuevos", {
              params: {
                ven:  cli,
                bus: '',
              }
            });
            setLineas(response.data);
        } catch (error) {
            console.error('Error al obtener las líneas:', error);
        }
    };

    fetchLineas();
}, []);

useEffect(() => {
  const fetchMarcas = async () => {
      try {
          const response = await axios.get("https://apigabtecbi.automarco.cl:3003/api/select-marcas-nuevos", {
            params: {
              ven:  cli,
              bus: '',
            }
          });
          setMarcas(response.data);
      } catch (error) {
          console.error('Error al obtener las marcas:', error);
      }
  };

  fetchMarcas();
}, []);



  const formatoNumero = (numero) => {
    return numero.toLocaleString('es-ES');
  };

  const abrirModal = (prodid) => {
    // Aquí puedes agregar la lógica para obtener los datos de MySQL para el registro específico
    // Puedes usar una función asíncrona y await para hacer la consulta a la base de datos

    // Ejemplo simulado de una función asíncrona ficticia para obtener datos de MySQL
    loadModalData(prodid).then(() => {
        setModalAbierta(true);
      });
    
};

  const cerrarModal = () => {
    setModalAbierta(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Manejo del cambio en el número de registros por página 
  const handleItemsPerPageChange = (e) => { 
    setItemsPerPage(Number(e.target.value)); 
    setCurrentPage(1); // Resetear a la primera página
  };

  const obtenerProdId = (item) => {
    return item.prod_id;
  };

  const handleCantidadChange = (prodId, e) => {
    let nuevaCantidad = e.target.value;
  
    // Validar que la cantidad sea un número y mayor o igual a 0
    const cantidadNumerica = parseInt(nuevaCantidad, 10);
    if (/^\d*$/.test(nuevaCantidad) && cantidadNumerica >= 0) {
      // Evitar que se ingrese un cero al comienzo
      if (nuevaCantidad.length === 1 && nuevaCantidad === "0") {
        nuevaCantidad = "";
      }
  
      setCantidadCarro((prevCantidad) => ({
        ...prevCantidad,
        [prodId]: nuevaCantidad
      }));
    } else {
      // Si la validación falla, o se ingresó un valor no válido, puedes mostrar un mensaje de error o realizar otra acción
      console.error('Ingrese un número válido mayor o igual a 0');
    }
  };
  
  
  const handleKeyDown = (e, prodId) => {
    // Permitir eliminar el último dígito con la tecla "Backspace"
    if (e.key === 'Backspace') {
      setCantidadCarro((prevCantidad) => ({
        ...prevCantidad,
        [prodId]: (prevCantidad[prodId] || '').slice(0, -1)
      }));
    }
  };

  const agregarAlCarro = () => {
    const itemsSeleccionados = data.map(item => {
      const prodId = obtenerProdId(item);
      const cantidad = cantidadCarro[prodId] && cantidadCarro[prodId].trim() !== '' ? cantidadCarro[prodId] : '0';
      const precio = item.prod_precio; 
      return { prodId, cantidad, precio };
    });

    const itemsValidos = itemsSeleccionados.filter(item => item.cantidad !== '0');

    if (itemsValidos.length > 0) {
      console.log('Agregado al carro:', itemsValidos);
      setCantidadCarro({});
      setCarroItems([...carroItems, ...itemsValidos]);
      if (typeof window.Android !== 'undefined') {
        window.Android.recibirDatos(JSON.stringify(itemsValidos));
      }
      //Android.recibirDatos(JSON.stringify(itemsValidos));
    } else {
      toast.error('Por favor, ingresa una cantidad válida.');
    }
  };
  // Calcula el índice de inicio y fin para los elementos de la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filtra los elementos a mostrar en la página actual
  const itemsFiltrados = data.filter((item) => {
    const lineaMatch = !lineaSeleccionada || item.cla_nombre === lineaSeleccionada;
    const marcaMatch = !marcaSeleccionada || item.marca_nombre === marcaSeleccionada; // Asumiendo que el campo para la marca en los datos se llama `marca_nombre`
  
    return lineaMatch && marcaMatch;
  });
  const totalFilteredPages = Math.ceil(itemsFiltrados.length / itemsPerPage);
  const itemsFiltrados2 = itemsFiltrados.slice(startIndex, endIndex);

  const maxVisiblePages = 10;
  const currentBlock = Math.ceil(currentPage / maxVisiblePages);
  const startPage = (currentBlock - 1) * maxVisiblePages + 1;
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalFilteredPages);

console.log('currentPage:', currentPage);
console.log('itemsFiltrados.length:', itemsFiltrados.length);
console.log('Total Pages:', Math.ceil(itemsFiltrados.length / itemsPerPage));

 
  

  return (
    <><h3 className='titulo'>Productos Nuevos</h3><div style={{ marginTop: "30px", marginLeft: "10px", marginRight: "0px" }}>
      <div className='form-inline'>
        <div id="field_wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <select className="form-select" style={{ marginLeft: '10px', width: '200px', fontSize: '12px' }} value={marcaSeleccionada}
            onChange={(e) => setMarcaSeleccionada(e.target.value)}>

            <option value="">Selecciona una marca</option>
            {marcas.map((marca) => (
              <option key={marca.marca_nombre} value={marca.marca_nombre}>
                {marca.marca_nombre}
              </option>
            ))}

          </select>

          <select className="form-select" style={{ marginLeft: '10px', width: '200px', fontSize: '12px' }} value={lineaSeleccionada}
            onChange={(e) => setLineaSeleccionada(e.target.value)}>

            <option value="">Selecciona una línea</option>
            {lineas.map((linea) => (
              <option key={linea.cla_nombre} value={linea.cla_nombre}>
                {linea.cla_nombre}
              </option>
            ))}

          </select>
          </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <select className="form-select"
                            style={{ width: '120px', fontSize: "12px" }} value={itemsPerPage}
                            onChange={handleItemsPerPageChange}>
                            <option value={10}>Mostrar 10</option>
                            <option value={20}>Mostrar 20</option>
                        </select>
                    </div>
        </div>
      </div>
      <br></br>
      <div className="my-class-form-control-group">
        <Table size="sm" className='titulos_tablas table-custom'>
          <thead>
            <tr>
              <th>Frecuentes</th>
              <th>Cod. Fabrica</th>
              <th>Linea</th>
              <th>Marca</th>
              <th>Precio</th>
              <th>Stock</th>
              <th>Aplicaciones</th>
              <th>Unidades</th>
            </tr>
          </thead>
          <tbody>
            {itemsFiltrados2.length === 0 ? (
              <tr className="even-row">
                <td colSpan="8"   style={{ textAlign: "center" }}>
                  No hay resultados
                </td>
              </tr>
            ) : (
              itemsFiltrados2.map((item, index) => {
                //const [data2, setData2] = useState([]);
                return (


                  <tr className={index % 2 === 0 ? 'even-row' : 'odd-row'} style={{ textAlign: "left" }} key={item.prod_id}>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}>{item.prod_id}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}>{item.id_prov}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {item.cla_nombre}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {item.marca_nombre}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> ${formatoNumero(item.prod_precio)}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {formatoNumero(item.prod_stock)}</td>
                    <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> <Button onClick={() => abrirModal(item.prod_id)} style={{ fontSize: "12px" }}>Aplicaciones</Button></td>
                    <td style={{ borderBottomWidth: "0px", width: "16%", whiteSpace: 'nowrap' }}> {item.multiplo ? (
                      <InputGroup>

                        <InputGroup.Text id="basic-addon2">
                          <select
                            value={cantidadCarro[item.prod_id] || ''}
                            onChange={(e) => handleCantidadChange(item.prod_id, e)}
                            disabled={productosHabilitados.has(item.prod_id)}>
                            {[...Array(100 / item.multiplo)].map((_, i) => (
                              <option key={i + 1}>{item.multiplo * (i + 1)}</option>
                            ))}
                          </select>
                        </InputGroup.Text>
                      </InputGroup>
                    ) : (
                      <FormControl
                        type="text"
                        value={cantidadCarro[item.prod_id] || ''}
                        onChange={(e) => handleCantidadChange(item.prod_id, e)}
                        onKeyDown={(e) => handleKeyDown(e, item.prod_id)}
                        style={{ width: "60px" }}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        disabled={productosHabilitados.has(item.prod_id)} />
                    )}</td>
                  </tr>
                );
              })
            )}

          </tbody>
        </Table>
        <Modal show={modalAbierta} onHide={cerrarModal}>
          <Modal.Header closeButton>
            <Modal.Title>{`Aplicaciones - Producto ${prodidModal || ''}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Marca</th>
                  <th>Modelo</th>
                  <th>Cilindrada</th>
                  <th>Posición</th>
                  <th>Año Inicio</th>
                  <th>Año Término</th>


                </tr>
              </thead>
              <tbody>
                {datosModal.map((item, index) => {
                  return (
                    <tr style={{ textAlign: "left" }} key={item.pm_id}>
                      <td style={{ borderBottomWidth: "0px", width: "12%" }}>{item.marca_nombre}</td>
                      <td style={{ borderBottomWidth: "0px", width: "12%" }}>{item.mod_id}</td>
                      <td style={{ borderBottomWidth: "0px", width: "12%" }}> {item.cilindrada}</td>
                      <td style={{ borderBottomWidth: "0px", width: "12%" }}> {item.ubi_nombre}</td>
                      <td style={{ borderBottomWidth: "0px", width: "12%" }}> {item.inicio}</td>
                      <td style={{ borderBottomWidth: "0px", width: "12%" }}> {item.fin}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>


            {/* Agrega más campos según tus datos personalizados */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cerrarModal}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: (endPage - startPage + 1) }, (_, index) => (
          <Pagination.Item
            key={startPage + index}
            active={startPage + index === currentPage}
            onClick={() => handlePageChange(startPage + index)}
          >
            {startPage + index}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalFilteredPages}
          onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
      <Button variant="primary" onClick={agregarAlCarro} style={{ float: 'right', marginRight: '180px', fontSize: '12px' }}>
        Agregar al Carro
      </Button>
    </div></>
    
  )
}

export default Tab4;