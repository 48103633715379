import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useLocation } from 'react-router-dom';

const Tab7 = ({cli}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [RangoSeleccionado, setRangoSeleccionado] = useState('');
  const location = useLocation();
  const productos = new URLSearchParams(location.search).get("productos");
  const [productosHabilitados, setProductosHabilitados] = useState(new Set());

  useEffect(() => {
    setProductosHabilitados(new Set(productos ? productos.split(',') : []));
  }, [productos]);

  const loadData = async () => {
    let buscar = document.getElementById('search').value;
    await axios.get("https://apigabtecbi.automarco.cl:3003/api/sinatencion", {
        params: {
          ven: cli,
          bus: buscar, 
        }
    }).then((response) => {
        console.log('Data received from backend:', response.data);
        setData(response.data);
     })
  };

  const effectRan = useRef(false);

  useEffect(() => {
    const input = document.getElementById('search');
    if (input) {
        input.setAttribute('autocomplete', 'off');
    }
    if (!effectRan.current) {
      loadData();
    }
    return () => {effectRan.current = true};
  }, []);

  useEffect(() => {
    const fetchLineas = async () => {
      try {
        const response = await axios.get("https://apigabtecbi.automarco.cl:3003/api/select-lineas");
        setLineas(response.data);
      } catch (error) {
        console.error('Error al obtener las líneas:', error);
      }
    };
    fetchLineas();
  }, []);

  async function busqueda() {
    await loadData(); // Esperar a que loadData termine
    // Ahora puedes realizar la búsqueda en los datos actualizados
    const buscar = document.getElementById('search').value;
    if (data && data.length > 0) {
      const resultadosFiltrados = data.filter((item) => item.nombre && item.nombre.includes(buscar)); // Verificar si item es definido antes de acceder a la propiedad
  
      // Realiza las operaciones necesarias con los resultados
      console.log(resultadosFiltrados);
  
      // Resto de tu lógica
    } else {
      console.log('No hay datos para realizar la búsqueda.');
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Manejo del cambio en el número de registros por página 
  const handleItemsPerPageChange = (e) => { 
    setItemsPerPage(Number(e.target.value)); 
    setCurrentPage(1); // Resetear a la primera página
  };

  // Calcula el índice de inicio y fin para los elementos de la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filtra los elementos a mostrar en la página actual
  const itemsFiltrados = data.slice(startIndex, endIndex);

  const maxVisiblePages = 10;
  const totalFilteredPages = Math.ceil(data.length / itemsPerPage);
  const currentBlock = Math.ceil(currentPage / maxVisiblePages);
  const startPage = (currentBlock - 1) * maxVisiblePages + 1;
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalFilteredPages);

  return (
    <><h3 className='titulo'>Clientes Sin Atención</h3><div style={{ marginTop: "30px", marginLeft: "10px", marginRight: "0px" }}>
      <div className='form-inline'>
        <div id="field_wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="my-class-form-control-group">
            <Form.Control
              type="search"
              id="search"
              autoComplete="off" // Desactivar autocompletado
              style={{ width: "300px", fontSize: '12px' }} />

          </div>
          <Button onClick={busqueda} style={{ marginLeft: '10px', fontSize: '12px' }}>Buscar</Button>
          </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <select className="form-select"
                            style={{ width: '120px', fontSize: "12px" }} value={itemsPerPage}
                            onChange={handleItemsPerPageChange}>
                            <option value={10}>Mostrar 10</option>
                            <option value={20}>Mostrar 20</option>
                        </select>
                    </div>
        </div>
      </div>
      <br></br>
      <div className="my-class-form-control-group">
        <Table size="sm" className='titulos_tablas table-custom'>
          <thead>
            <tr>
              <th>RUT</th>
              <th>Sucursal</th>
              <th>Razón Social</th>
              <th>Direccion</th>
              <th>Comuna</th>
            </tr>
          </thead>
          <tbody>
            {itemsFiltrados.length === 0 ? (
              <tr className="even-row" >
                <td colSpan="8" style={{ textAlign: "center" }}>
                  No hay resultados
                </td>
              </tr>
            ) : (
              itemsFiltrados.map((item, index) => (
                <tr className={index % 2 === 0 ? 'even-row' : 'odd-row'} style={{ textAlign: "left", backgroundColor: index % 2 === 0 ? "#ffffff" : "#99cccc" }} key={`${item.cli_rut}-${item.cli_sec}`}>
                  <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}>{item.cli_rut}</td>
                  <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}>{item.cli_sec}</td>
                  <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {item.cli_razon_social}</td>
                  <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {item.cli_direccion}</td>
                  <td style={{ borderBottomWidth: "0px", width: "12%", whiteSpace: 'nowrap' }}> {item.cli_comuna}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: (endPage - startPage + 1) }, (_, index) => (
          <Pagination.Item
            key={startPage + index}
            active={startPage + index === currentPage}
            onClick={() => handlePageChange(startPage + index)}
          >
            {startPage + index}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalFilteredPages}
          onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
    </div></>
  );
};

export default Tab7;
